<script>
  export let color = '#000'
</script>

<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 319.26 212.16" style="enable-background:new 0 0 319.26 212.16;" xml:space="preserve">
<style type="text/css">
  svg{
    height:100%;
    width:auto;
  }
</style>
<g>
	<path fill={color} class="st0" d="M42.45,64.18H56.4v33.75h26.23v11.57H56.4v21.46h31.12l-5.96,11.92H42.45V64.18z M85.38,64.18V76.7H59.14
		l6.2-12.52H85.38z"/>
	<path fill={color}  class="st0" d="M134.86,63.94c11.92,2.98,22.89,14.79,22.89,39.83c0,24.33-11.09,40.3-31.84,40.3s-31.84-15.98-31.84-40.3
		c0-25.16,12.04-40.78,32.08-40.78v13.35c-12.28,0-17.77,9.78-17.77,27.66c0,18.24,5.49,28.26,17.53,28.26s17.53-10.02,17.53-28.26
		c0-17.77-5.96-26.23-14.55-27.43L134.86,63.94z"/>
	<path fill={color}  class="st0" d="M195.8,142.88h-13.95v-29.69l-23.49-49.01h15.26l22.18,46.38V142.88z M218.45,64.18l-13.24,30.29h-14.31
		l13.24-30.29H218.45z"/>
	<path fill={color}  class="st0" d="M222.74,130.95h20.87c6.68,0,9.42-2.62,9.42-7.75c0-4.77-1.91-8.11-8.82-12.52L233.6,104
		c-8.82-5.6-11.92-12.4-11.92-20.39c0-13.12,8.7-19.44,24.33-19.44h12.76l6.08,12.52H245.4c-6.2,0-9.42,2.27-9.42,7.63
		c0,4.17,1.79,7.39,6.44,10.49l12.64,8.47c7.04,4.65,12.28,10.25,12.28,19.79c0,11.8-7.04,19.79-21.7,19.79h-16.93L222.74,130.95z"
		/>
</g>
<g>
	<path fill={color}  class="st0" d="M48.58,47.51h-3.12V35.38h-4v-2.6h11.12v2.6h-4V47.51z"/>
	<path fill={color}  class="st0" d="M67.96,47.51h-3.11v-6.36h-5.83v6.36h-3.12V32.78h3.12v5.77h5.83v-5.77h3.11V47.51z"/>
	<path fill={color}  class="st0" d="M81.19,47.51h-8.48V32.78h8.48v2.56h-5.36v3.24h4.99v2.56h-4.99v3.8h5.36V47.51z"/>
	<path fill={color}  class="st0" d="M97.95,35.17c-1.18,0-2.09,0.44-2.73,1.33c-0.65,0.88-0.97,2.11-0.97,3.69c0,3.29,1.23,4.93,3.7,4.93
		c1.03,0,2.29-0.26,3.76-0.78v2.62c-1.21,0.5-2.56,0.76-4.05,0.76c-2.14,0-3.78-0.65-4.92-1.95c-1.14-1.3-1.7-3.17-1.7-5.6
		c0-1.53,0.28-2.87,0.84-4.03c0.56-1.15,1.36-2.04,2.4-2.65c1.04-0.61,2.27-0.92,3.67-0.92c1.43,0,2.87,0.35,4.31,1.04l-1.01,2.54
		c-0.55-0.26-1.11-0.49-1.66-0.68C99.03,35.26,98.48,35.17,97.95,35.17z"/>
	<path fill={color}  class="st0" d="M119.04,40.12c0,2.44-0.6,4.31-1.81,5.62c-1.21,1.31-2.94,1.97-5.2,1.97c-2.26,0-3.99-0.65-5.2-1.97
		c-1.21-1.31-1.81-3.19-1.81-5.64c0-2.45,0.61-4.32,1.82-5.62c1.21-1.29,2.95-1.94,5.21-1.94s4,0.65,5.19,1.96
		C118.44,35.8,119.04,37.68,119.04,40.12z M108.29,40.12c0,1.65,0.31,2.89,0.94,3.72c0.62,0.83,1.56,1.25,2.8,1.25
		c2.49,0,3.74-1.66,3.74-4.97c0-3.32-1.24-4.98-3.72-4.98c-1.24,0-2.18,0.42-2.81,1.25C108.6,37.24,108.29,38.48,108.29,40.12z"/>
	<path fill={color}  class="st0" d="M136.5,40.12c0,2.44-0.6,4.31-1.81,5.62c-1.21,1.31-2.94,1.97-5.2,1.97c-2.26,0-3.99-0.65-5.2-1.97
		c-1.21-1.31-1.81-3.19-1.81-5.64c0-2.45,0.61-4.32,1.82-5.62c1.21-1.29,2.95-1.94,5.21-1.94s4,0.65,5.19,1.96
		C135.9,35.8,136.5,37.68,136.5,40.12z M125.74,40.12c0,1.65,0.31,2.89,0.94,3.72c0.62,0.83,1.56,1.25,2.8,1.25
		c2.49,0,3.74-1.66,3.74-4.97c0-3.32-1.24-4.98-3.72-4.98c-1.24,0-2.18,0.42-2.81,1.25C126.06,37.24,125.74,38.48,125.74,40.12z"/>
	<path fill={color}  class="st0" d="M150.76,37.37c0,1.58-0.5,2.8-1.49,3.64c-0.99,0.84-2.4,1.26-4.23,1.26h-1.34v5.24h-3.12V32.78h4.71
		c1.79,0,3.15,0.38,4.08,1.15C150.29,34.7,150.76,35.85,150.76,37.37z M143.71,39.71h1.03c0.96,0,1.68-0.19,2.16-0.57
		c0.48-0.38,0.72-0.93,0.72-1.66c0-0.73-0.2-1.27-0.6-1.62c-0.4-0.35-1.03-0.52-1.88-0.52h-1.42V39.71z"/>
	<path fill={color}  class="st0" d="M163.06,47.51h-8.48V32.78h8.48v2.56h-5.36v3.24h4.99v2.56h-4.99v3.8h5.36V47.51z"/>
	<path fill={color}  class="st0" d="M170.29,41.86v5.65h-3.12V32.78h4.29c2,0,3.48,0.36,4.44,1.09c0.96,0.73,1.44,1.84,1.44,3.32
		c0,0.87-0.24,1.64-0.71,2.31c-0.48,0.68-1.15,1.2-2.03,1.59l4.33,6.42h-3.47l-3.52-5.65H170.29z M170.29,39.32h1.01
		c0.99,0,1.72-0.17,2.19-0.49c0.47-0.33,0.71-0.85,0.71-1.55c0-0.7-0.24-1.2-0.72-1.49c-0.48-0.3-1.22-0.44-2.23-0.44h-0.95V39.32z"
		/>
	<path fill={color}  class="st0" d="M199.17,32.78v9.53c0,1.09-0.24,2.04-0.73,2.86c-0.49,0.82-1.19,1.45-2.11,1.88
		c-0.92,0.44-2.01,0.65-3.26,0.65c-1.89,0-3.37-0.48-4.41-1.46c-1.05-0.97-1.57-2.3-1.57-3.99v-9.49h3.11v9.02
		c0,1.14,0.23,1.97,0.69,2.5c0.46,0.53,1.21,0.8,2.27,0.8c1.02,0,1.76-0.27,2.22-0.8c0.46-0.53,0.69-1.37,0.69-2.51v-9H199.17z"/>
	<path fill={color}  class="st0" d="M216.88,47.51h-3.97l-6.41-11.14h-0.09c0.13,1.97,0.19,3.37,0.19,4.21v6.93h-2.79V32.78h3.94l6.4,11.03h0.07
		c-0.1-1.91-0.15-3.27-0.15-4.06v-6.97h2.81V47.51z"/>
	<path  fill={color} class="st0" d="M227.13,47.51h-6.7v-1.77l1.79-0.83v-9.53l-1.79-0.83v-1.77h6.7v1.77l-1.79,0.83v9.53l1.79,0.83V47.51z"/>
	<path fill={color}  class="st0" d="M244.05,40.12c0,2.44-0.6,4.31-1.81,5.62c-1.21,1.31-2.94,1.97-5.2,1.97c-2.26,0-3.99-0.65-5.2-1.97
		c-1.21-1.31-1.81-3.19-1.81-5.64c0-2.45,0.61-4.32,1.82-5.62c1.21-1.29,2.95-1.94,5.21-1.94s4,0.65,5.19,1.96
		C243.45,35.8,244.05,37.68,244.05,40.12z M233.3,40.12c0,1.65,0.31,2.89,0.94,3.72c0.62,0.83,1.56,1.25,2.8,1.25
		c2.49,0,3.74-1.66,3.74-4.97c0-3.32-1.24-4.98-3.72-4.98c-1.24,0-2.18,0.42-2.81,1.25C233.62,37.24,233.3,38.48,233.3,40.12z"/>
	<path fill={color}  class="st0" d="M261.21,47.51h-3.97l-6.41-11.14h-0.09c0.13,1.97,0.19,3.37,0.19,4.21v6.93h-2.79V32.78h3.94l6.4,11.03h0.07
		c-0.1-1.91-0.15-3.27-0.15-4.06v-6.97h2.81V47.51z"/>
</g>
<g>
	<path fill={color}  class="st0" d="M54.29,177.32l-1.44-4.72h-7.24l-1.44,4.72h-4.53l7-19.93h5.14l7.03,19.93H54.29z M51.85,169.07
		c-1.33-4.28-2.08-6.7-2.25-7.26c-0.17-0.56-0.29-1.01-0.36-1.33c-0.3,1.16-1.15,4.02-2.57,8.59H51.85z"/>
	<path fill={color}  class="st0" d="M66.92,169.7v7.62h-4.21v-19.85h5.78c2.7,0,4.69,0.49,5.99,1.47c1.29,0.98,1.94,2.47,1.94,4.47
		c0,1.17-0.32,2.21-0.96,3.12c-0.64,0.91-1.55,1.62-2.73,2.14l5.84,8.65H73.9l-4.74-7.62H66.92z M66.92,166.28h1.36
		c1.33,0,2.31-0.22,2.95-0.67c0.63-0.44,0.95-1.14,0.95-2.09c0-0.94-0.32-1.61-0.97-2.01s-1.65-0.6-3.01-0.6h-1.28V166.28z"/>
	<path fill={color}  class="st0" d="M90.89,160.69c-1.58,0-2.81,0.6-3.68,1.79c-0.87,1.19-1.3,2.85-1.3,4.97c0,4.43,1.66,6.64,4.98,6.64
		c1.39,0,3.08-0.35,5.06-1.05v3.53c-1.63,0.68-3.45,1.02-5.46,1.02c-2.89,0-5.1-0.88-6.62-2.63c-1.53-1.75-2.29-4.26-2.29-7.54
		c0-2.06,0.38-3.87,1.13-5.42c0.75-1.55,1.83-2.74,3.24-3.57c1.41-0.83,3.06-1.24,4.95-1.24c1.93,0,3.86,0.47,5.81,1.4l-1.36,3.42
		c-0.74-0.35-1.49-0.66-2.24-0.92C92.35,160.82,91.61,160.69,90.89,160.69z"/>
	<path fill={color}  class="st0" d="M117.83,177.32h-4.19v-8.57h-7.86v8.57h-4.21v-19.85h4.21v7.78h7.86v-7.78h4.19V177.32z"/>
	<path fill={color}  class="st0" d="M131.65,177.32h-9.03v-2.39l2.42-1.11v-12.84l-2.42-1.11v-2.39h9.03v2.39l-2.42,1.11v12.84l2.42,1.11V177.32z"
		/>
	<path fill={color}  class="st0" d="M144.09,177.32h-4.21v-16.34h-5.39v-3.5h14.99v3.5h-5.39V177.32z"/>
	<path fill={color}  class="st0" d="M165.36,177.32h-11.43v-19.85h11.43v3.45h-7.22v4.36h6.72v3.45h-6.72v5.12h7.22V177.32z"/>
	<path fill={color}  class="st0" d="M179.32,160.69c-1.58,0-2.81,0.6-3.68,1.79c-0.87,1.19-1.3,2.85-1.3,4.97c0,4.43,1.66,6.64,4.98,6.64
		c1.39,0,3.08-0.35,5.06-1.05v3.53c-1.63,0.68-3.45,1.02-5.46,1.02c-2.89,0-5.1-0.88-6.62-2.63c-1.53-1.75-2.29-4.26-2.29-7.54
		c0-2.06,0.38-3.87,1.13-5.42c0.75-1.55,1.83-2.74,3.24-3.57c1.41-0.83,3.06-1.24,4.95-1.24c1.93,0,3.86,0.47,5.81,1.4l-1.36,3.42
		c-0.74-0.35-1.49-0.66-2.24-0.92C180.78,160.82,180.04,160.69,179.32,160.69z"/>
	<path fill={color}  class="st0" d="M197.65,177.32h-4.21v-16.34h-5.39v-3.5h14.99v3.5h-5.39V177.32z"/>
	<path fill={color}  class="st0" d="M223.64,157.47v12.84c0,1.47-0.33,2.75-0.98,3.85c-0.66,1.11-1.6,1.95-2.84,2.54
		c-1.24,0.59-2.71,0.88-4.4,0.88c-2.55,0-4.53-0.65-5.95-1.96c-1.41-1.31-2.12-3.1-2.12-5.37v-12.79h4.2v12.15
		c0,1.53,0.31,2.65,0.92,3.37c0.61,0.71,1.63,1.07,3.05,1.07c1.38,0,2.37-0.36,2.99-1.08c0.62-0.72,0.93-1.85,0.93-3.39v-12.12
		H223.64z"/>
	<path fill={color}  class="st0" d="M234.09,169.7v7.62h-4.21v-19.85h5.78c2.7,0,4.69,0.49,5.99,1.47c1.29,0.98,1.94,2.47,1.94,4.47
		c0,1.17-0.32,2.21-0.96,3.12c-0.64,0.91-1.55,1.62-2.73,2.14l5.84,8.65h-4.67l-4.74-7.62H234.09z M234.09,166.28h1.36
		c1.33,0,2.31-0.22,2.95-0.67c0.63-0.44,0.95-1.14,0.95-2.09c0-0.94-0.32-1.61-0.97-2.01s-1.65-0.6-3.01-0.6h-1.28V166.28z"/>
	<path fill={color}  class="st0" d="M261.06,177.32h-11.43v-19.85h11.43v3.45h-7.22v4.36h6.72v3.45h-6.72v5.12h7.22V177.32z"/>
</g>
</svg>

<style>
  svg{
    height:100%;
    width:auto;
  }
</style>